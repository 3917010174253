$(function() {
    $('a[href^="#"]').bind('click.smoothscroll', function(e) {
        e.preventDefault();
        var target = this.hash,
            $target = $(target);

        $('html, body').stop().animate({
            'scrollTop': $target.offset().top
        }, 900, 'swing', function() {
            window.location.hash = target;
        });
    });

    $('[name=propertyValue]').maskMoney({
        'prefix': '£',
        'precision': 0,
        'allowNegative': false
    });

    $('.not-sure button').click(function() {
        $(this).fadeOut();
        $('.details').slideDown();
    })
    $('.retrieve-estimated-value').click(function() {
        $('.calculate .loading').css('height', '0');
        $('.calculate .loading').css('width', '0');
        $('.property_info .loading').css('transform', 'scale(1)');
        axios.post('/retrieve-property-value', {
                postcode: $('[name=postcode]').val(),
                bedroomCount: $('[name=bedroomCount]').val(),
                propertyType: $('[name=propertyType]').val()
            })
            .then(function(response) {
                $('.details').slideUp();
                $('[name=propertyValue]').maskMoney('mask', response.data.data.average);
            });
    });
    $('#guide-form').submit(function(event) {
        event.preventDefault();
        $(this).find('button[type=submit]').prop('disabled', true);
        var self = this;
        var value = $('[name=propertyValue]').val().replace(/\D/g, '');
        $('[name=propertyValue]').maskMoney('destroy');
        $('[name=propertyValue]').val(value);
        self.submit();
    });
});


axios.interceptors.request.use(function(config) {
    $('.loading').fadeIn().css('display', 'inline-block');
    setTimeout(function() {
        $('.result_loading').fadeIn();
    }, 400);


    const token = window.localStorage.token;
    if (token) {
        config.headers.Authorization = `token ${token}`
    }
    return config
}, function(error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function(response) {
    $('.result_loading').fadeOut();
    $('.loading').fadeOut();

    return response;
}, function(error) {
    return Promise.reject(error);
});

function toggleModal() {
    $('#scrim').fadeToggle();
    if ($('.modal_container').css('display') == 'none') {
        $('.modal_container').fadeIn().css('display', 'flex');
        $('html').addClass('no-scroll');
        $('body').addClass('no-scroll');
    } else {
        $('.modal_container').fadeOut();
        $('html').removeClass('no-scroll');
        $('body').removeClass('no-scroll');
    }
    if ($('html').hasClass('no-scroll')) {
        $(this).css('overflow', 'hidden');
        $('body').css('overflow', 'hidden');
    } else {
        $(this).css('overflow', 'initial');
        $('body').css('overflow', 'initial');
    }
}


$(function() {
    $('[name=property_value]').maskMoney({
        'prefix': '£',
        'precision': 0,
        'allowNegative': false
    });
    $('input').change(function() {
        $(this).parent().removeClass('error');
        $(this).parent().children('.message').remove();
        $(this).parent().children('br').remove();
    });
    $('.property_value button').click(function() {
        $(this).fadeOut();
        $('.property_info .property_info').slideDown();
    })
    $('.retrieve_estimated_value').click(function() {
        axios.post('/retrieve-property-value', {
                postcode: $('[name=address]').val(),
                bedroom_count: $('[name=bedroom_count]').val(),
                property_type: $('[name=property_type]').val()
            })
            .then(function(response) {
                $('[name=property_value]').maskMoney('mask', response.data.data.average);
            });
    });


    $('.calculate_button button').click(function() {
        var data = [];
        data['propertyValue'] = $('[name=propertyValue]').val().replace(/\D/g, '');
        data['postcode'] = $('[name=postcode]').val();
        data['firstName'] = $('[name=firstName]').val();
        data['lastName'] = $('[name=lastName]').val();
        data['age'] = $('[name=age]').val();
        data['contactNumber'] = $('[name=contactNumber]').val();
        data['emailAddress'] = $('[name=emailAddress]').val();

        $('.result').fadeOut();
        axios.post('/retrieve-calculation', {
                property_value: data.property_value,
                address: data.address,
                full_name: data.full_name,
                date_of_birth: data.date_of_birth,
                contact_number: data.contact_number,
                email_address: data.email_address
            })
            .then(function(response) {
                setTimeout(function() {
                    $('.calculate_button button').html('Recalculate');
                    $('#calculate .value').html(response.data.maximumLoanAmount).simpleMoneyFormat();
                    $('#calculate .value').prepend('£');
                    $('.result').fadeIn();
                }, 400);
            }).catch(function(error) {
                $('span .message').remove();
                $('.loading').css('display', 'none');
                _.forEach(error.response.data.errors, function(value, key) {
                    $('[name=' + key + ']').parent().append('<span class="message">' + value + '</span>');
                });
            });;

    });
});